import React, { useState } from 'react';
import portfolio1 from '../assets/images/kotelezettsegvallalas-img.png';
import portfolio2 from '../assets/images/munkafolyamat-img.png';
import portfolio3 from '../assets/images/horsesite-img.png';
import portfolio4 from '../assets/images/edesvaros-img.png';
import portfolio5 from '../assets/images/greenriver-img.png';
import portfolio6 from '../assets/images/imo-webshop-img.png';
import content from '../assets/data.json';

export default function Portfolio({language}) {

    return (
        <section className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <h2>
                        {language === "hu" ? content.portfolio.title.hu : content.portfolio.title.en}
                        <span aria-hidden="true">{language === "hu" ? content.portfolio.title.hu : content.portfolio.title.en}</span>
                    </h2>
                </div>
                <div className="col-12 col-md-10">
                    <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio6} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[0].name.hu : content.portfolio.projects[0].name.en}</h3>
                                <ul>
                                    <li>{language === "hu" ? content.portfolio.development[0].hu : content.portfolio.development[0].en}</li>
                                    <li>Laravel</li>
                                    <li>React</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[0].description.hu : content.portfolio.projects[0].description.en} 
                                </p>
                                {/* <a className="btn btn-custom" href="https://www.zabiakedina.hu/immanuel-otthon/">Megtekintés</a> */}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio1} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[1].name.hu : content.portfolio.projects[1].name.en}</h3>
                                <ul>
                                    <li>Pure PHP</li>
                                    <li>{language === "hu" ? content.portfolio.development[1].hu : content.portfolio.development[1].en}</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[1].description.hu : content.portfolio.projects[1].description.en}</p>
                                <a className="btn btn-custom" href="https://www.zabiakedina.hu/immanuel-otthon/">{language === "hu" ? content.portfolio.view.hu : content.portfolio.view.en}</a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio2} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[2].name.hu : content.portfolio.projects[2].name.en}</h3>
                                <ul>
                                    <li>Pure PHP</li>
                                    <li>API</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[2].description.hu : content.portfolio.projects[2].description.en}</p>
                                <a className="btn btn-custom" href="https://github.com/edinazabiak/secret-server">Github</a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio3} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[3].name.hu : content.portfolio.projects[3].name.en}</h3>
                                <ul>
                                    <li>Sitebuild</li>
                                    <li>HTML</li>
                                    <li>CSS</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[3].description.hu : content.portfolio.projects[3].description.en}</p>
                                <a className="btn btn-custom" href="https://portfolio.zabiakedina.hu/horse-barn/index.html">Demo</a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio4} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[4].name.hu : content.portfolio.projects[4].name.en}</h3>
                                <ul>
                                    <li>JQuery</li>
                                    <li>Ajax</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[4].description.hu : content.portfolio.projects[4].description.en}</p>
                                <a className="btn btn-custom" href="https://github.com/edinazabiak/edes-varos">GitHub</a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <div className="portfolio-box">
                                <img src={portfolio5} alt="Kép" />
                                <h3>{language === "hu" ? content.portfolio.projects[5].name.hu : content.portfolio.projects[5].name.en}</h3>
                                <ul>
                                    <li>G-Portál</li>
                                    <li>Sitebuild</li>
                                </ul>
                                <p>{language === "hu" ? content.portfolio.projects[5].description.hu : content.portfolio.projects[5].description.en}</p>
                                <a className="btn btn-custom" href="http://greenrivergardens.gportal.hu/">{language === "hu" ? content.portfolio.view.hu : content.portfolio.view.en}</a>
                            </div>
                        </div>

                        <div className="col-12 text-center my-4">
                            <a className="btn btn-custom" href="http://portfolio.zabiakedina.hu/">{language === "hu" ? content.portfolio.more.hu : content.portfolio.more.en}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}