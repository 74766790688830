import React from 'react';
import content from '../assets/data.json';

export default function Footer({language}) {

  return (
    <footer className="container-fluid">
        <div className="row">
            <div className="col-12 text-center">{new Date().getFullYear()} &copy; {language === "hu" ? content.footer.description.hu : content.footer.description.en}</div>
        </div>
    </footer>
  )
}