import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Qualification from './components/Qualification';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import Particle from './components/helpers/Particle';
import ScrollUp from './components/helpers/ScrollUp';
import useDarkMode from './components/helpers/useDarkMode';
import useSwitchLanguage from './components/helpers/useSwitchLanguage';
import { GlobalStyles, lightTheme, darkTheme } from './components/helpers/GlobalStyles';

import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';


function App() {

  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;
  const [language, toggleLanguage] = useSwitchLanguage();
  const siteLanguage = language === "en" ? "en" : "hu";

  return (
    <>
      <Helmet htmlAttributes={{
          lang: siteLanguage,
          }} 
      ></ Helmet>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles/>
        <Particle theme={theme}/>
        <Header theme={theme} toggleTheme={toggleTheme} language={language} toggleLanguage={toggleLanguage}/>
        <main>
          <Home language={language}/>
          <AboutMe language={language}/>
          <Skills language={language}/>
          <Qualification language={language}/>
          <Portfolio language={language}/>
        </main>
        <Footer language={language}/>
        <ScrollUp />
      </ThemeProvider>
      
    </>
  );
}

export default App;