import React from 'react'
import { FaHtml5, FaCss3Alt, FaReact, FaBootstrap, FaPhp, FaPython, FaLaravel, FaWordpress, FaDocker, FaLinux } from "react-icons/fa";
import { FaGitAlt } from "react-icons/fa6";
import { DiJavascript, DiJqueryLogo, DiMysql } from "react-icons/di";
import { MdLanguage } from "react-icons/md";
import content from '../assets/data.json'

export default function Skills({ language }) {

    return (
        <section className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <h2>
                        {language === "hu" ? content.skills.title.hu : content.skills.title.en}
                        <span aria-hidden="true">{language === "hu" ? content.skills.title.hu : content.skills.title.en}</span>
                    </h2>
                </div>
                <div className="col-12 col-md-10">
                    <h3>{language === "hu" ? content.skills.frontend.hu : content.skills.frontend.en}</h3>
                    <ul className="row justify-content-center skills-mosaic">
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaHtml5 /></span>
                                <p>HTML5</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaCss3Alt /></span>
                                <p>CSS3</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaBootstrap /></span>
                                <p>Bootstrap</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><DiJavascript /></span>
                                <p>JavaScript</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><DiJqueryLogo /></span>
                                <p>JQuery</p>
                                <small>{language === "hu" ? content.skills.intermediate.hu : content.skills.intermediate.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaReact /></span>
                                <p>React</p>
                                <small>{language === "hu" ? content.skills.novice.hu : content.skills.novice.en}</small>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-10">
                    <h3>{language === "hu" ? content.skills.backend.hu : content.skills.backend.en}</h3>
                    <ul className="row justify-content-center skills-mosaic">
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaPhp /></span>
                                <p>PHP</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaLaravel /></span>
                                <p>Laravel</p>
                                <small>{language === "hu" ? content.skills.novice.hu : content.skills.novice.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><DiMysql /></span>
                                <p>SQL/MySQL</p>
                                <small>{language === "hu" ? content.skills.proficient.hu : content.skills.proficient.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaPython /></span>
                                <p>Python</p>
                                <small>{language === "hu" ? content.skills.intermediate.hu : content.skills.intermediate.en}</small>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-10">
                    <h3>{language === "hu" ? content.skills.others.hu : content.skills.others.en}</h3>
                    <ul className="row justify-content-center skills-mosaic">
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaWordpress /></span>
                                <p>WordPress</p>
                                <small>{language === "hu" ? content.skills.intermediate.hu : content.skills.intermediate.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaGitAlt /></span>
                                <p>GIT</p>
                                <small>{language === "hu" ? content.skills.intermediate.hu : content.skills.intermediate.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaLinux /></span>
                                <p>Linux</p>
                                <small>{language === "hu" ? content.skills.intermediate.hu : content.skills.intermediate.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><FaDocker /></span>
                                <p>Docker</p>
                                <small>{language === "hu" ? content.skills.novice.hu : content.skills.novice.en}</small>
                            </div>
                        </li>
                        <li className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="skill-box">
                                <span><MdLanguage /></span>
                                <p>{language === "hu" ? content.skills.language_name.hu : content.skills.language_name.en}</p>
                                <small>{language === "hu" ? content.skills.language_level.hu : content.skills.language_level.en}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}