import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle `
    body {
        background: ${({ theme }) => theme.secondaryColor300};
        color: ${({ theme }) => theme.primaryColor50};
    }
    h2 {
        color: ${({ theme }) => theme.primaryColor50};
    }
    h2 span {
        color: ${({ theme }) => theme.secondaryColorVariant};
    }
    h3 {
        background-color: ${({ theme }) => theme.primaryColor200};
        color: ${({ theme }) => theme.secondaryColor300};
    }
    small {
        color: ${({ theme }) => theme.primaryColor50};
    }
    footer {
        background: ${({ theme }) => theme.secondaryColor200};
        color: ${({ theme }) => theme.primaryColor50};
    }
    .dark-mode {
        color: ${({ theme }) => theme.primaryColor100};
    }
    .dark-mode:hover {
        color: ${({ theme }) => theme.primaryColor300};
    }
    .information-box {
        background-color: ${({ theme }) => theme.secondaryColor200};
    }
    .information-mosaic li {
        background-color: ${({ theme }) => theme.secondaryColor100};
        color: ${({ theme }) => theme.primaryColor50};
    }
    .information-mosaic li:nth-child(2n) {
        background-color: ${({ theme }) => theme.secondaryColorVariant};
        color: ${({ theme }) => theme.primaryColor50};
    }
    .information-mosaic span,
    .information-mosaic li:nth-child(2n) span {
        color: ${({ theme }) => theme.primaryColor50};
    }
    .information-menu li {
        background-color: ${({ theme }) => theme.secondaryColorVariant};
    }
    .information-menu li:hover, 
    .information-menu li:active, 
    .information-menu li:focus, 
    .information-menu li.active {
        background-color: ${({ theme }) => theme.primaryColor300};
        color: ${({ theme }) => theme.secondaryColor300};
    }
    .timeline-calendar,
    .timeline-list {
        color: ${({ theme }) => theme.primaryColor50};
    }
    .timeline-subtitle {
        color: ${({ theme }) => theme.primaryColor100};
    }
    .timeline-rounder__active {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.secondaryColor200},0 0 0 5px ${({ theme }) => theme.primaryColor300};
    }
    .timeline-line, 
    .timeline-rounder {
        background-color: ${({ theme }) => theme.primaryColor300};
    }
    .timeline-line__gradient {
        background: linear-gradient(0deg, rgba(0,0,0,0) 20%, ${({ theme }) => theme.primaryColor300} 100%);
    }
    .portfolio-box {
        background-color: ${({ theme }) => theme.secondaryColor200};
        color: ${({ theme }) => theme.primaryColor50};
    }
    .portfolio-box h3 {
        color: ${({ theme }) => theme.primaryColor100};
    }
`;

export const lightTheme = {
};

export const darkTheme = {
    primaryColor50: 'var(--primary-color-50)',
    primaryColor100: 'var(--primary-color-100)',
    primaryColor200: 'var(--primary-color-200)',
    primaryColor300: 'var(--primary-color-300)',
    secondaryColor100: 'var(--secondary-color-100)',
    secondaryColor200: 'var(--secondary-color-200)',
    secondaryColor300: 'var(--secondary-color-300)',
    secondaryColorVariant: 'var(--secondary-color-variant)',
};