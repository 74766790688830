import React from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';

export default function ScrollUp() {
    function scrollUp() {
        const scrollUp = document.getElementById('scroll-up');
        if (this.scrollY > 460) {
            scrollUp.classList.add('show-scroll');
        } else {
            scrollUp.classList.remove('show-scroll');
        }
    }
    window.addEventListener('scroll', scrollUp)

    return (
        <a href="#root" className="scrollup" id="scroll-up">
            <AiOutlineArrowUp className='scrollup-icon' />
        </a>

    )
}