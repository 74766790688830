import { useState, useEffect } from 'react';

export default function useDarkMode() {

  const getTheme = () => {
    return JSON.parse(localStorage.getItem("theme"))
  }

  const [theme, setTheme] = useState(getTheme());
  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [theme])

  const toggleTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  }
  return [theme, toggleTheme];
}