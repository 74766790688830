import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { FaCalendarAlt } from 'react-icons/fa';
import { MdSchool } from "react-icons/md";
import { BsBriefcaseFill } from "react-icons/bs";
import content from '../assets/data.json';

export default function Qualification({language}) {
    const [option, setOption] = useState("work");

    return (
        <section className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <h2>
                        {language === "hu" ? content.timeline.title.hu : content.timeline.title.en}
                        <span aria-hidden="true">{language === "hu" ? content.timeline.title.hu : content.timeline.title.en}</span>
                    </h2>
                </div>
                <div className="col-12 col-md-10">
                    <div className="information-box">
                        <ul className="information-menu">
                            <li className={`${option === "work" ? "active" : null}`} onClick={() => setOption("work")}>
                                <span><BsBriefcaseFill /></span>
                                {language === "hu" ? content.timeline.work_menu.hu : content.timeline.work_menu.en}
                            </li>
                            <li className={`${option === "education" ? "active" : null}`} onClick={() => setOption("education")}>
                                <span><MdSchool /></span>
                                {language === "hu" ? content.timeline.education_menu.hu : content.timeline.education_menu.en}
                            </li>
                        </ul>

                        {option == "work" ?
                            <div className="information-content">
                                {language === "hu" ?
                                    content.timeline.work.map((data, index) => 
                                        <div className="timeline-data">
                                            <MediaQuery minWidth={992}>
                                                <div className="timeline-calendar">
                                                    {data.date.hu}
                                                </div>
                                            </MediaQuery>
                                            <div>
                                                <span className={index == 0 ? "timeline-rounder timeline-rounder__active" : "timeline-rounder"}></span>
                                                <span className={index == 3 ? "timeline-line timeline-line__gradient" : "timeline-line"}></span>
                                            </div>
                                            <div>
                                                <MediaQuery maxWidth={991}>
                                                    <div className="timeline-calendar">
                                                        <FaCalendarAlt />
                                                        {data.date.hu}
                                                    </div>
                                                </MediaQuery>
                                                <p className="timeline-title">{data.name.hu}</p>
                                                <span className="timeline-subtitle">{data.place.hu}</span>
                                                <ul className="timeline-list">
                                                    {data.description.map((text) => <li>{text.hu}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                :
                                content.timeline.work.map((data, index) => 
                                    <div className="timeline-data">
                                        <MediaQuery minWidth={992}>
                                            <div className="timeline-calendar">
                                                {data.date.en}
                                            </div>
                                        </MediaQuery>
                                        <div>
                                            <span className={index == 0 ? "timeline-rounder timeline-rounder__active" : "timeline-rounder"}></span>
                                            <span className={index == 3 ? "timeline-line timeline-line__gradient" : "timeline-line"}></span>
                                        </div>
                                        <div>
                                            <MediaQuery maxWidth={991}>
                                                <div className="timeline-calendar">
                                                    <FaCalendarAlt />
                                                    {data.date.en}
                                                </div>
                                            </MediaQuery>
                                            <p className="timeline-title">{data.name.en}</p>
                                            <span className="timeline-subtitle">{data.place.en}</span>
                                            <ul className="timeline-list">
                                                {data.description.map((text) => <li>{text.en}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div> 
                            : // EDUCATION
                            <div className="information-content">
                                {language === "hu" ?
                                    content.timeline.education.map((data, index) => 
                                        <div className="timeline-data">
                                            <MediaQuery minWidth={992}>
                                                <div className="timeline-calendar">
                                                    {data.date.hu}
                                                </div>
                                            </MediaQuery>
                                            <div>
                                                <span className="timeline-rounder"></span>
                                                <span className={index == 2 ? "timeline-line timeline-line__gradient" : "timeline-line"}></span>
                                            </div>
                                            <div>
                                                <MediaQuery maxWidth={991}>
                                                    <div className="timeline-calendar">
                                                        <FaCalendarAlt />
                                                        {data.date.hu}
                                                    </div>
                                                </MediaQuery>
                                                <p className="timeline-title">{data.name.hu}</p>
                                                <span className="timeline-subtitle">{data.place.hu}</span>
                                                <ul className="timeline-list">
                                                    {data.description.map((text) => <li>{text.hu}</li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                :
                                content.timeline.education.map((data, index) => 
                                    <div className="timeline-data">
                                        <MediaQuery minWidth={992}>
                                            <div className="timeline-calendar">
                                                {data.date.en}
                                            </div>
                                        </MediaQuery>
                                        <div>
                                            <span className="timeline-rounder"></span>
                                            <span className={index == 2 ? "timeline-line timeline-line__gradient" : "timeline-line"}></span>
                                        </div>
                                        <div>
                                            <MediaQuery maxWidth={991}>
                                                <div className="timeline-calendar">
                                                    <FaCalendarAlt />
                                                    {data.date.en}
                                                </div>
                                            </MediaQuery>
                                            <p className="timeline-title">{data.name.en}</p>
                                            <span className="timeline-subtitle">{data.place.en}</span>
                                            <ul className="timeline-list">
                                                {data.description.map((text) => <li>{text.en}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                            }
                    </div>
                </div>
            </div>
        </section>
    )
}