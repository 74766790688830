import React from 'react'
import { MdDarkMode, MdLightMode } from 'react-icons/md'

export default function Header({ theme, toggleTheme, language, toggleLanguage }) {
  return (
    <header>
      <nav>
        <ul>
          <li className={language === "en" ? "nav-icon active" : "nav-icon"} onClick={toggleLanguage}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
              <path fill="#012169" d="M0 0h640v480H0z" />
              <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
              <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
              <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
              <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
            </svg>
          </li>
          <li className={language === "hu" ? "nav-icon active" : "nav-icon"} onClick={toggleLanguage}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-hu" viewBox="0 0 640 480">
              <g fill-rule="evenodd">
                <path fill="#fff" d="M640 480H0V0h640z" />
                <path fill="#388d00" d="M640 480H0V320h640z" />
                <path fill="#d43516" d="M640 160.1H0V.1h640z" />
              </g>
            </svg>
          </li>
          <li className="dark-mode" onClick={toggleTheme}>
            {theme === "dark" ? <MdLightMode /> : <MdDarkMode />}
          </li>
        </ul>

      </nav>
    </header>
  )
}