import { useState, useEffect } from 'react';

export default function useSwitchLanguage() {

  const getLanguage = () => {
    return JSON.parse(localStorage.getItem("language"))
  }

  const [language, setLanguage] = useState(getLanguage());
  useEffect(() => {
    localStorage.setItem("language", JSON.stringify(language))
  }, [language])

  const toggleLanguage = () => {
    language === "en" ? setLanguage("hu") : setLanguage("en");
  }
  return [language, toggleLanguage];
}