import React, { useState } from 'react'
import { FaBirthdayCake } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdPlace, MdAlternateEmail } from "react-icons/md";
import content from '../assets/data.json'

export default function AboutMe({language}) {
  const [option, setOption] = useState('description');

  return (
    <section className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <div className="information-box">
            <div className="information-content">
              {
                language === "hu" ? content.me.description.map((data) => <p>{data.hu}</p>) : content.me.description.map((data) => <p>{data.en}</p>)
              }
            </div>
          </div>
          <ul className="row information-mosaic">
            <li className="col-12 col-sm-6 col-lg-3"><span><FaBirthdayCake /></span>{language === "hu" ? content.me.age.hu : content.me.age.en}</li>
            <li className="col-12 col-sm-6 col-lg-3"><span><MdPlace /></span>{language === "hu" ? content.me.location.hu : content.me.location.en}</li>
            <li className="col-12 col-sm-6 col-lg-3"><span><FaPhone /></span>+36 30 269 0696</li>
            <li className="col-12 col-sm-6 col-lg-3"><span><MdAlternateEmail /></span>edi.zabi@gmail.com</li>
          </ul>
        </div>
      </div>
    </section>
  )
}