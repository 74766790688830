import React from 'react'
import ME from '../assets/images/zabiak_edina_fenykep.png'
import content from '../assets/data.json'

export default function Home({ language }) {
  return (
    <section className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-5 align-content-center text-center">
          <small>{language === "hu" ? content.home.subtitle.hu : content.home.subtitle.en}</small>
          <h1>{language === "hu" ? content.home.name.hu : content.home.name.en}</h1>
          <p>{language === "hu" ? content.home.description.hu : content.home.description.en}</p>
        </div>
        <div className="col-12 col-sm-4 col-md-5 text-center">
          <img className="main-img" src={ME} alt="Egy portré fotó rólam" />
        </div>
      </div>
    </section>
  )
}